body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

@font-face {
  font-family: "Brown-Light";
  src: url("./assets/fonts/Brown-Light.woff") format("woff");
}

@font-face {
  font-family: "Brown-Bold";
  src: url("./assets/fonts/Brown-Bold.woff") format("woff");
}

@font-face {
  font-family: "Brown-Regular";
  src: url("./assets/fonts/Brown-Regular.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova-Black";
  src: url("./assets/fonts/ProximaNova-Black.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("./assets/fonts/ProximaNova-Bold.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("./assets/fonts/ProximaNova-Extrabld.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("./assets/fonts/ProximaNova-Light.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("./assets/fonts/ProximaNova-Regular.woff") format("woff");
}
@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("./assets/fonts/ProximaNova-Semibold.woff") format("woff");
}

@font-face {
  font-family: "ProximaNova-Thin";
  src: url("./assets/fonts/ProximaNovaT-Thin.woff") format("woff");
}
