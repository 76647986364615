.pagination {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 36px;
  width: 426px;
  background: white;
  list-style: none;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: #2f3b55;
  cursor: pointer;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  border: none;
  outline: none;
}
.active {
  border: none;
  padding: 2px 7px;
  height: 20px;
  background: #2f3b55;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-family: "ProximaNova-Bold";
  box-shadow: none;
  outline: none !important;
}
.active:focus {
  outline: none;
}

/* .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 9px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d8d8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 0px;
  bottom: -6px;
  background-color: #7f8382;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0070f5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7f8382;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} */

@media (max-width: 685px) {
  .pagination {
    width: 100%;
  }
  /* .switch {
    width: 24px !important;
    height: 5px !important;
  }
  .slider:before {
    height: 13px !important;
    width: 13px !important;
    bottom: -4px !important;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  } */
}
